<template>
  <div v-if="value">
    {{ value ? formatDateTime(value) : "" }} <br />
    <span class="error--text">{{ displayCountdown }}</span>
  </div>
</template>

<script>
export default {
  name: "CountDown",
  props: {
    value: {
      type: String,
      default: () => null,
    },
  },
  data: () => ({
    timer: null,
    displayCountdown: null,
  }),
  mounted() {
    if (this.value) {
      console.log(this.value);
      this.timer = setInterval(this.coundown, 1000);
    }
  },
  beforeDestroy() {
    if (this.value) {
      clearInterval(this.timer);
    }
  },
  methods: {
    coundown() {
      const destinationTime = new Date(this.value);
      const currentTime = new Date();
      const timeDifference = destinationTime.getTime() - currentTime.getTime();

      if (timeDifference <= 0) {
        clearInterval(this.timer);
        this.displayCountdown = null;
        return;
      }

      const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor(
        (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
      );
      const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

      if (days > 0) {
        this.displayCountdown = `${days} ngày`;
      } else if (hours > 0) {
        this.displayCountdown = `${hours} giờ`;
      } else if (minutes > 0) {
        this.displayCountdown = `${minutes} phút`;
      } else if (seconds > 0) {
        this.displayCountdown = `${seconds} giây`;
      } else {
        this.displayCountdown = `${hours} giờ ${minutes} phút ${seconds} giây`;
      }
    },
  },
};
</script>

<style scoped></style>
